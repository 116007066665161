@font-face {
  font-family: "fredericka_the_greatregular";
  src: url("/public/fonts/fredericka-the-great.regular-webfont.eot");
  src: url("/public/fonts/fredericka-the-great.regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/public/fonts/fredericka-the-great.regular-webfont.woff2") format("woff2"),
    url("/public/fonts/fredericka-the-great.regular-webfont.woff") format("woff"),
    url("/public/fonts/fredericka-the-great.regular-webfont.ttf") format("truetype"),
    url("/public/fonts/fredericka-the-great.regular-webfont.svg#fredericka_the_greatregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
/* ubuntu-regular - latin */  
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ubuntu-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
       url('/fonts/ubuntu-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/ubuntu-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/ubuntu-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/ubuntu-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/ubuntu-v12-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/ubuntu-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
       url('/fonts/ubuntu-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/ubuntu-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/ubuntu-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/ubuntu-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/ubuntu-v12-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Architects Daughter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/architects-daughter-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Architects Daughter Regular'), local('ArchitectsDaughter-Regular'),
       url('/fonts/architects-daughter-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/architects-daughter-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/architects-daughter-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/architects-daughter-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/architects-daughter-v10-latin-regular.svg#ArchitectsDaughter') format('svg'); /* Legacy iOS */
}
