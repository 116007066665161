header {
    background-color: theme-color("secondary");
    padding-top: 1rem;
    padding-bottom: 2rem;
    
    a:hover {
        text-decoration: none;
    }

    h1 {
        font-size: 5rem;
        font-weight: bold;
        text-transform: uppercase;
    }
}
