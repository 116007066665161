$fa-font-path: "/public/fonts";

@import "font-awesome/scss/font-awesome"; 
@import "./config/fonts.scss"; 
@import "./config/bootstrap.scss";

@import "./mixins/**/*.scss";

@import "./components/**/*.scss";
@import "./mixins/**/*.scss";

