$theme-colors: (
  "primary": #111,
  "secondary": #ffff00,
);

$headings-font-family: "Architects Daughter";

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2;
